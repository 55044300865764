define("ember-composability-tools/index", ["exports", "ember-composability-tools/mixins/child", "ember-composability-tools/mixins/parent", "ember-composability-tools/mixins/render-block"], function (_exports, _child, _parent, _renderBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ChildMixin", {
    enumerable: true,
    get: function () {
      return _child.default;
    }
  });
  Object.defineProperty(_exports, "ParentMixin", {
    enumerable: true,
    get: function () {
      return _parent.default;
    }
  });
  Object.defineProperty(_exports, "RenderBlockMixin", {
    enumerable: true,
    get: function () {
      return _renderBlock.default;
    }
  });
});