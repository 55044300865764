define("ember-inputmask/components/one-way-ssn-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @class OneWaySSNMask
   */
  var _default = _exports.default = _oneWayInputMask.default.extend({
    /**
     * @field mask
     * @override
     */
    mask: '999-99-9999'
  });
});