define('ember-cli-file-saver/mixins/file-saver', ['exports', 'file-saver'], function (exports, _fileSaver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({

    saveCanvasAs(filename, canvas) {
      canvas.toBlob(blob => this.saveAs(blob, filename));
    },

    saveTextAs(filename, text) {
      this.saveFileAs(filename, text, 'text/plain;charset=utf-8');
    },

    saveFileAs(filename, content, contentType) {
      this.saveAs(new Blob([content], { type: contentType }), filename);
    },

    saveAs() {
      _fileSaver.default.saveAs(...arguments);
    }

  });
});